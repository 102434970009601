import { TransactionsTable } from '../components/tables/TransactionsTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useAssertCurrent } from '../hooks/useCurrent';
import { Role } from '../model/Role';
import { PageHeader } from '../components/layout/PageHeader';

export const TransactionsPage = () => {
  const api = useApi();
  const { roles, participant } = useAssertCurrent();
  const transactions = usePagedData(api.transactions.listTransactions);

  return (
    <main className="transactions-page">
      <PageHeader title="Transacties" />
      <TransactionsTable
        transactions={transactions}
        participantId={roles.includes(Role.admin) ? undefined : participant?.id}
      />
    </main>
  );
};
