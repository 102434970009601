import { Participant } from './Participant';
import { Option } from '../components/form/Select';
import { transactionTypeNames } from './Transaction';

export interface TradeRound {
  id: number;
  startDate: string;
  endDate: string;
  registrationsCount: number;
}

export interface TradeRoundDetails extends TradeRound {
  registrations: TradeRoundRegistration[];
  transactionsCreated: boolean;
  totalBuy: number;
  totalBuyActual: number;
  totalSell: number;
  totalSellActual: number;
}

export type CreateTradeRound = Omit<TradeRound, 'id' | 'registrationsCount'>;

export type TradeRoundRegistrationType = 'buy' | 'sell';

export const tradeRoundRegistrationTypeOptions: Option<TradeRoundRegistrationType>[] =
  [
    {
      value: 'buy',
      displayValue: transactionTypeNames.buy,
    },
    {
      value: 'sell',
      displayValue: transactionTypeNames.sell,
    },
  ];

export interface TradeRoundRegistration {
  id: number;
  participant: Participant;
  type: TradeRoundRegistrationType;
  requestedAmount: number;
  actualAmount: number;
}

export type CreateTradeRoundRegistration = Omit<
  TradeRoundRegistration,
  'id' | 'participant' | 'actualAmount'
>;

export interface TradeRoundStatus {
  current?: TradeRound;
  upcoming?: TradeRound;
  registration?: TradeRoundRegistration;
}

export interface UpdateTradeRoundRegistration {
  actualAmount: number;
}
