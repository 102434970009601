import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { classNames } from '../../utils/classNames';

type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonKind = 'basic' | 'primary' | 'link';

export interface Props extends ButtonProps {
  text?: string | number;
  icon?: IconProp;
  loading?: boolean;
  kind?: ButtonKind;
}

export const Button = ({
  text,
  icon,
  loading,
  type = 'button',
  kind,
  ...props
}: Props) => {
  let resolvedKind: ButtonKind = 'basic';
  if (kind) {
    // If we have an explicit button kind, use that.
    resolvedKind = kind;
  } else if (type === 'submit') {
    // If we don't have a button kind, and the type is submit, this is a primary button.
    resolvedKind = 'primary';
  }

  return (
    <button
      {...props}
      className={classNames(
        'button',
        props.className,
        loading && 'loading',
        icon && 'has-icon-left',
        resolvedKind
      )}
      type={type}
      disabled={props.disabled || loading}
    >
      <span className="content">
        {icon && <FontAwesomeIcon className="icon" icon={icon} />}
        {text && <span className="text">{text}</span>}
      </span>
      <div className="loader-wrapper">
        <FontAwesomeIcon icon="spinner" pulse className="loader" />
      </div>
    </button>
  );
};
