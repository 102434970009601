import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

/**
 * Get the current hash value.
 * If options are given, and the actual hash is not one of those,
 * the first option is returned instead.
 *
 * @param options The valid hash values.
 */
export function useHash(
  options?: string[]
): [string, (newValue: string) => void] {
  let hash = useLocation().hash;
  const location = useLocation();
  const navigate = useNavigate();

  // Strip a leading hash.
  if (hash.startsWith('#')) {
    hash = hash.substring(1);
  }

  // If we have options, check if the current hash is valid.
  if (options && !options.includes(hash)) {
    hash = options[0];
  }

  const setValue = useCallback(
    (newValue: string) => {
      // Call navigate with the current search, to preserve that while changing the hash.
      navigate({
        search: location.search,
        hash: newValue,
      });
    },
    [location.search, navigate]
  );

  return [hash, setValue];
}
