import { useCallback, useState } from 'react';
import { Input } from '../../components/form/Input';
import styles from './AuthPage.module.scss';
import { useApi } from '../../hooks/useApi';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../router';
import { Register } from '../../services/AuthService';
import { useFormValue } from '../../hooks/useFormValue';
import { FormField, Form } from '../../components/form/Form';

export const RegisterPage = () => {
  const [registration, setField] = useFormValue<Register>({
    userName: '',
    email: '',
    password: '',
    companyName: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const api = useApi();
  const navigate = useNavigate();

  const submit = useCallback(async () => {
    if (registration.password !== confirmPassword) {
      throw new Error('Je wachtwoorden komen niet overeen.');
    }

    await api.auth.register(registration);
    navigate('/');
  }, [api.auth, confirmPassword, navigate, registration]);

  return (
    <div className={styles.authPage}>
      <div className={styles.modal}>
        <h1>Start met ParticiPro</h1>
        <p>
          Probeer 30 dagen lang alles van ParticiPro. Geen beperkingen,
          verplichtingen, of opstartkosten.
        </p>
        <Form onSubmit={submit} submitLabel="Account aanmaken" fullWidth>
          <FormField label="Naam">
            <Input
              type="text"
              value={registration.userName}
              onChange={setField('userName')}
              required
            />
          </FormField>
          <FormField label="E-mailadres">
            <Input
              type="email"
              value={registration.email}
              onChange={setField('email')}
              required
            />
          </FormField>
          <FormField label="Bedrijfsnaam">
            <Input
              type="text"
              value={registration.companyName}
              onChange={setField('companyName')}
              required
            />
          </FormField>
          <FormField label="Wachtwoord">
            <Input
              type="password"
              value={registration.password}
              onChange={setField('password')}
              required
            />
          </FormField>
          <FormField label="Herhaal wachtwoord">
            <Input
              type="password"
              value={confirmPassword}
              onChange={setConfirmPassword}
              required
            />
          </FormField>
        </Form>
        <div className={styles.bottomText}>
          Heb je al een account? <Link to={Paths.login}>Log in</Link>
        </div>
      </div>
    </div>
  );
};
