import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Injector, InjectorProvider } from 'react-service-injector';
import { Router } from './router';

// Set up Font Awesome.
library.add(fas);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <InjectorProvider value={new Injector()}>
      <Router />
    </InjectorProvider>
  </React.StrictMode>
);
