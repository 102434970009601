import { useCallback, useState } from 'react';
import { Input } from '../../components/form/Input';
import styles from './AuthPage.module.scss';
import { useApi } from '../../hooks/useApi';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../router';
import { FormField, Form } from '../../components/form/Form';

export const ForgotPassPage = () => {
  const [email, setEmail] = useState('');
  const api = useApi();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);

  const submit = useCallback(async () => {
    await api.auth.forgotPassword({ email });
    setDone(true);
  }, [api.auth, email]);

  return (
    <div className={styles.authPage}>
      <div className={styles.modal}>
        <h1>Wachtwoord vergeten</h1>
        {done ? (
          <>
            <p>
              Er is een e-mail verstuurd om je wachtwoord opnieuw in te stellen.
            </p>
            <div className={styles.bottomText}>
              <Link to={Paths.login}>Terug naar inloggen</Link>
            </div>
          </>
        ) : (
          <>
            <p>
              Vul hier je e-mailadres in. Je ontvangt een e-mail met instructies
              om een nieuw wachtwoord in te stellen.
            </p>
            <Form
              onSubmit={submit}
              submitLabel="Verstuur e-mail"
              altActionLabel="Terug naar inloggen"
              onAltAction={() => navigate(Paths.login)}
              fullWidth
            >
              <FormField label="E-mailadres">
                <Input
                  type="email"
                  value={email}
                  onChange={setEmail}
                  required
                />
              </FormField>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};
