import { PaginatedTable } from '../components/table/PaginatedTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { useState } from 'react';
import { CreateParticipantModal } from '../components/modals/CreateParticipantModal';
import { Button } from '../components/form/Button';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/date';
import { Toggle } from '../components/form/Toggle';
import { Input } from '../components/form/Input';
import { PageHeader } from '../components/layout/PageHeader';

export const ParticipantsPage = () => {
  const api = useApi();
  const [archived, setArchived] = useState(false);
  const [search, setSearch] = useState('');
  const participantsData = usePagedData(api.participants.listParticipants, {
    archived,
    search,
  });
  const [createParticipantModal, setCreateParticipantModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <main>
        <PageHeader
          title="Deelnemers"
          actions={
            <Button
              text="Nieuwe deelnemer"
              icon="plus"
              onClick={() => setCreateParticipantModal(true)}
              kind="primary"
            />
          }
        />
        <div className="filters">
          <Input value={search} onChange={setSearch} placeholder="Zoek..." />
          <Toggle
            checked={archived}
            onChange={setArchived}
            label="Gearchiveerd"
          />
        </div>
        <PaginatedTable
          pagedData={participantsData}
          onClickRow={(m) => navigate(`/participants/${m.id}`)}
          columns={[
            {
              name: 'Naam',
              valueFn: (m) => m.user.name,
            },
            {
              name: 'E-mail',
              valueFn: (m) => m.user.email,
              className: 'hide-large',
            },
            {
              name: 'Startdatum',
              valueFn: (m) => m.startDate && formatDate(m.startDate),
            },
            {
              name: 'Einddatum',
              valueFn: (m) => m.endDate && formatDate(m.endDate),
              className: 'hide-small',
            },
            !archived && {
              name: 'Certificaten',
              valueFn: (m) => m.certificatesCount,
              className: 'hide-small',
            },
          ]}
        />
      </main>
      {createParticipantModal && (
        <CreateParticipantModal
          onClose={() => setCreateParticipantModal(false)}
          onSubmit={(m) => navigate(`/participants/${m.id}`)}
        />
      )}
    </>
  );
};
