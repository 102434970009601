import { useQuery } from './useQuery';
import { useCallback } from 'react';

export function useIntQuery(
  key: string,
  defaultValue = 0
): [number, (newValue: number) => void] {
  const [value, setValue] = useQuery(key, defaultValue.toString(10));

  let intVal = parseInt(value, 10);
  if (isNaN(intVal)) {
    intVal = defaultValue;
  }

  const setIntValue = useCallback(
    (newValue: number) => {
      setValue(newValue.toString(10));
    },
    [setValue]
  );

  return [intVal, setIntValue];
}
