import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import {
  CreateTransactions,
  TransactionType,
  transactionTypeOptions,
} from '../../model/Transaction';
import { Select } from '../form/Select';
import { today } from '../../utils/date';
import { useState } from 'react';
import { Certificate } from '../../model/Certificate';
import { Button } from '../form/Button';
import { Input } from '../form/Input';
import { FormField, Form } from '../form/Form';

export interface Props extends CloseSubmitProps {
  participantId: number;
}

const transactionTypeResult: Record<TransactionType, string> = {
  buy: 'gekocht',
  sell: 'verkocht',
  gift: 'gegift',
};

export const CreateTransactionsModal = ({
  onClose,
  onSubmit,
  participantId,
}: Props) => {
  const api = useApi();
  const [transactions, setField] = useFormValue<
    Omit<CreateTransactions, 'participantId'>
  >(() => ({
    type: 'buy',
    amount: 1,
    date: today(),
  }));
  const [affectedCerts, setAffectedCerts] = useState<Certificate[]>();

  return (
    <Modal title="Nieuwe transacties" onClose={onClose}>
      {affectedCerts ? (
        <>
          <h3>
            {affectedCerts.length} certificaten{' '}
            {transactionTypeResult[transactions.type]}:
          </h3>
          <div>{affectedCerts.map((c) => c.fullName).join(', ')}</div>
          <Button text="Sluiten" onClick={onClose} className="actions" />
        </>
      ) : (
        <Form
          submitLabel="Opslaan"
          onSubmit={() =>
            api.transactions
              .createTransactions({
                ...transactions,
                participantId,
              })
              .then(setAffectedCerts)
              .then(() => onSubmit())
          }
          fullWidth
        >
          <FormField label="Type">
            <Select
              value={transactions.type}
              onChange={setField('type')}
              required
              options={transactionTypeOptions}
            />
          </FormField>
          <FormField label="Certificaten">
            <Input
              type="number"
              value={transactions.amount}
              onChange={setField('amount')}
              required
              min={1}
              step={1}
            />
          </FormField>
          <FormField label="Datum">
            <Input
              type="date"
              value={transactions.date}
              onChange={setField('date')}
              required
            />
          </FormField>
        </Form>
      )}
    </Modal>
  );
};
