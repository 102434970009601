import { useApi } from '../../hooks/useApi';
import { CloseSubmitProps, Modal } from '../Modal';
import { CreateValuation } from '../../model/Valuation';
import { useFormValue } from '../../hooks/useFormValue';
import { today } from '../../utils/date';
import { Input } from '../form/Input';
import { FormField, Form } from '../form/Form';

export const CreateValuationModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps) => {
  const api = useApi();
  const [valuation, setField] = useFormValue<CreateValuation>(() => ({
    date: today(),
    totalValue: 0,
  }));

  return (
    <Modal title="Nieuwe waardering" onClose={onClose}>
      <Form
        submitLabel="Opslaan"
        onSubmit={() =>
          api.valuations.createValuation(valuation).then(() => {
            onSubmit();
            onClose();
          })
        }
        fullWidth
      >
        <FormField label="Datum">
          <Input
            value={valuation.date}
            onChange={setField('date')}
            required
            type="date"
          />
        </FormField>
        <FormField label="Totale waarde (euro's)">
          <Input
            value={valuation.totalValue}
            onChange={setField('totalValue')}
            required
            min={0}
            type="euro-whole"
          />
        </FormField>
      </Form>
    </Modal>
  );
};
