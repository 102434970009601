import { useCallback, useState } from 'react';
import { Input } from '../../components/form/Input';
import styles from './AuthPage.module.scss';
import { useApi } from '../../hooks/useApi';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/query/useQuery';
import { Paths } from '../../router';
import { FormField, Form } from '../../components/form/Form';

export const ResetPassPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email] = useQuery('email');
  const [token] = useQuery('token');
  const api = useApi();
  const navigate = useNavigate();

  const submit = useCallback(async () => {
    if (password !== confirmPassword) {
      throw new Error('Je nieuwe wachtwoorden komen niet overeen.');
    }

    await api.auth.resetPassword({ email, password, token });
    navigate(`${Paths.login}?password-reset`);
  }, [api.auth, confirmPassword, email, navigate, password, token]);

  return (
    <div className={styles.authPage}>
      <div className={styles.modal}>
        <h1>Nieuw wachtwoord instellen</h1>
        <Form
          onSubmit={submit}
          submitLabel="Wachtwoord instellen"
          submitDisabled={!password || !confirmPassword}
          fullWidth
        >
          <FormField label="Nieuw wachtwoord">
            <Input
              type="password"
              value={password}
              onChange={setPassword}
              required
            />
          </FormField>
          <FormField label="Herhaal wachtwoord">
            <Input
              type="password"
              value={confirmPassword}
              onChange={setConfirmPassword}
              required
            />
          </FormField>
        </Form>
        <div className={styles.bottomText}>
          <Link to={Paths.login}>Terug naar inloggen</Link>
        </div>
      </div>
    </div>
  );
};
