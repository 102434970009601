import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { UpdatePassword, UpdateUser } from '../model/User';

export class UsersService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public updateUser(updates: UpdateUser): Promise<void> {
    return this.api.jsonPut('/api/user', updates);
  }

  public updatePassword(updates: UpdatePassword): Promise<void> {
    return this.api.jsonPost('/api/user/password', updates);
  }
}
