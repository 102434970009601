import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { CertificatePrice, Stats } from '../model/Stats';

export class StatsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public getStats(): Promise<Stats> {
    return this.api.jsonGet('/api/stats');
  }

  public getCertPrice(): Promise<number> {
    return this.api
      .jsonGet<CertificatePrice>('/api/stats/cert-price')
      .then((r) => r.price);
  }
}
