import { ReactNode } from 'react';
import styles from './Modal.module.scss';
import { Button } from './form/Button';

export interface Props {
  children: ReactNode;
  title: string;
  onClose: () => void;
}

export interface CloseProps {
  onClose: () => void;
}

export interface CloseSubmitProps<S = undefined> extends CloseProps {
  onSubmit: S extends undefined ? () => void : (data: S) => void;
}

export const Modal = ({ children, title, onClose }: Props) => (
  <div className={styles.modal}>
    <div className={styles.content}>
      <div className={styles.title}>
        <h2>{title}</h2>
        <Button onClick={onClose} icon="times" />
      </div>
      {children}
    </div>
  </div>
);
