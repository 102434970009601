import { useApi } from '../../hooks/useApi';
import { useAssertCurrent } from '../../hooks/useCurrent';
import { useFormValue } from '../../hooks/useFormValue';
import { UpdateTenant } from '../../model/Tenant';
import { useCallback } from 'react';
import {
  FieldGroup,
  Form,
  FormField,
  FormSection,
} from '../../components/form/Form';
import { Input } from '../../components/form/Input';

export const CompanySettings = () => {
  const api = useApi();
  const { tenant: initialTenant } = useAssertCurrent();
  const [tenant, setField] = useFormValue<UpdateTenant>({
    ...initialTenant,
  });

  const onSubmit = useCallback(async () => {
    await api.tenants.updateTenant(tenant);
  }, [api.tenants, tenant]);

  return (
    <div>
      <Form onSubmit={onSubmit} submitLabel="Opslaan">
        <FormField label="Bedrijfsnaam">
          <Input value={tenant.name} onChange={setField('name')} required />
        </FormField>
        <FormSection text="Factuurgegevens" />
        <FormField label="E-mailadres">
          <Input
            value={tenant.billingEmail}
            onChange={setField('billingEmail')}
            required
            placeholder="facturen@example.com"
          />
        </FormField>
        <FormField label="Straat en huisnummer">
          <Input
            value={tenant.streetAndNumber}
            onChange={setField('streetAndNumber')}
            placeholder="Lange straat 123"
          />
        </FormField>
        <FieldGroup>
          <FormField label="Postcode">
            <Input
              value={tenant.postalCode}
              onChange={setField('postalCode')}
              placeholder="1234 AB"
            />
          </FormField>
          <FormField label="Plaats">
            <Input
              value={tenant.city}
              onChange={setField('city')}
              placeholder="Amsterdam"
            />
          </FormField>
        </FieldGroup>
      </Form>
    </div>
  );
};
