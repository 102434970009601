import { PaginatedTable } from '../components/table/PaginatedTable';
import { useApi } from '../hooks/useApi';
import { usePagedData } from '../hooks/usePagedData';
import { formatWholeEuros } from '../utils/currency';
import { useState } from 'react';
import { CreateValuationModal } from '../components/modals/CreateValuationModal';
import { Button } from '../components/form/Button';
import { formatDate } from '../utils/date';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/layout/PageHeader';

export const ValuationsPage = () => {
  const api = useApi();
  const valuationsData = usePagedData(api.valuations.listValuations);
  const [createValuationModal, setCreateValuationModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <main className="valuations-page">
        <PageHeader
          title="Waarderingen"
          actions={
            <Button
              text="Nieuwe waardering"
              icon="plus"
              onClick={() => setCreateValuationModal(true)}
              kind="primary"
            />
          }
        />
        <PaginatedTable
          pagedData={valuationsData}
          onClickRow={(v) => navigate(`/valuations/${v.id}`)}
          columns={[
            {
              name: 'Datum',
              valueFn: (v) => formatDate(v.date),
            },
            {
              name: 'Peildatum',
              valueFn: (v) => formatDate(v.referenceDate),
            },
            {
              name: 'Totale waarde',
              valueFn: (v) => formatWholeEuros(v.totalValue),
            },
          ]}
        />
      </main>
      {createValuationModal && (
        <CreateValuationModal
          onClose={() => setCreateValuationModal(false)}
          onSubmit={valuationsData.reloadPage}
        />
      )}
    </>
  );
};
