import React, { ReactElement } from 'react';
import styles from './Tabs.module.scss';
import { classNames } from '../utils/classNames';
import { useHash } from '../hooks/useHash';
import { Select } from './form/Select';

export interface Props {
  tabs: (Tab | false)[];
}

export interface Tab {
  name: string;
  element: ReactElement;
}

export const Tabs = ({ tabs: allTabs }: Props) => {
  const tabs = allTabs.filter((t): t is Tab => !!t);
  const names = tabs.map((t) => t.name);
  const [hash, setHash] = useHash(names);

  return (
    <div>
      <Select
        className={styles.selectMenu}
        options={names.map((n) => ({
          value: n,
          displayValue: n,
        }))}
        onChange={setHash}
      />
      <div className={styles.menu}>
        {tabs.map(({ name }) => (
          <button
            onClick={() => setHash(name)}
            key={name}
            className={classNames(
              styles.menuItem,
              name === hash && styles.active
            )}
          >
            {name}
          </button>
        ))}
        <div className={styles.spacer} />
      </div>
      <div className={styles.content}>
        {tabs.find(({ name }) => name === hash)?.element}
      </div>
    </div>
  );
};
