import { Injector } from 'react-service-injector';
import { ParticipantsService } from './ParticipantsService';
import { TransactionsService } from './TransactionsService';
import { SharesService } from './SharesService';
import { CertificatesService } from './CertificatesService';
import { ValuationsService } from './ValuationsService';
import { DividendsService } from './DividendsService';
import { TradeRoundsService } from './TradeRoundsService';
import { StatsService } from './StatsService';
import { IncomeTaxService } from './IncomeTaxService';
import { ChecksService } from './ChecksService';
import { AuthService } from './AuthService';
import { RolesService } from './RolesService';
import { TenantsService } from './TenantsService';
import { UsersService } from './UsersService';
import { BlobService } from './BlobService';

export class ApiService {
  public readonly auth: AuthService;

  public readonly blob: BlobService;
  public readonly certificates: CertificatesService;
  public readonly checks: ChecksService;
  public readonly dividends: DividendsService;
  public readonly incomeTax: IncomeTaxService;
  public readonly participants: ParticipantsService;
  public readonly roles: RolesService;
  public readonly shares: SharesService;
  public readonly stats: StatsService;
  public readonly tenants: TenantsService;
  public readonly trading: TradeRoundsService;
  public readonly transactions: TransactionsService;
  public readonly users: UsersService;
  public readonly valuations: ValuationsService;

  public constructor(injector: Injector) {
    this.auth = injector.resolve(AuthService);

    this.blob = injector.resolve(BlobService);
    this.certificates = injector.resolve(CertificatesService);
    this.checks = injector.resolve(ChecksService);
    this.dividends = injector.resolve(DividendsService);
    this.incomeTax = injector.resolve(IncomeTaxService);
    this.participants = injector.resolve(ParticipantsService);
    this.roles = injector.resolve(RolesService);
    this.shares = injector.resolve(SharesService);
    this.stats = injector.resolve(StatsService);
    this.tenants = injector.resolve(TenantsService);
    this.trading = injector.resolve(TradeRoundsService);
    this.transactions = injector.resolve(TransactionsService);
    this.users = injector.resolve(UsersService);
    this.valuations = injector.resolve(ValuationsService);
  }
}
