import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { BlobEntityType, BlobList } from '../model/Blob';

export class BlobService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public upload(type: BlobEntityType, id: number, file: File): Promise<void> {
    return this.api.formPost(`/api/blob/${type}/${id}`, { file });
  }

  public list(type: BlobEntityType, id: number): Promise<BlobList> {
    return this.api.jsonGet(`/api/blob/${type}/${id}`);
  }
}
