import { ParticipantsInTime } from '../../model/Stats';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatDate } from '../../utils/date';
import { splineSeries } from './series';

export interface Props {
  participantsOverTime: ParticipantsInTime[];
}

export const ParticipantsChart = ({ participantsOverTime }: Props) => {
  const options: Options = {
    title: {
      text: 'Deelnemers over tijd',
    },
    xAxis: {
      categories: participantsOverTime.map((v) => formatDate(v.date)),
    },
    yAxis: {
      title: {
        text: 'Aantal deelnemers',
      },
      min: 0,
    },
    series: [
      {
        ...splineSeries(0),
        name: 'Deelnemers',
        data: participantsOverTime.map((m) => m.participants),
      },
    ],
    tooltip: {
      shared: true,
    },
  };

  return (
    <div className="chart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
