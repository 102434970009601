import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { Role } from '../model/Role';

export class RolesService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public getCurrentRoles(): Promise<Role[]> {
    return this.api.jsonGet(`/api/roles`);
  }
}
