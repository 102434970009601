import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { TransactionsPage } from './pages/TransactionsPage';
import { ParticipantsPage } from './pages/ParticipantsPage';
import { ParticipantPage } from './pages/ParticipantPage';
import { CertificatesPage } from './pages/CertificatesPage';
import { SharesPage } from './pages/SharesPage';
import { SharePage } from './pages/SharePage';
import { ValuationsPage } from './pages/ValuationsPage';
import { ValuationPage } from './pages/ValuationPage';
import { DividendsPage } from './pages/DividendsPage';
import { DividendPage } from './pages/DividendPage';
import { CurrentTradeRoundPage } from './pages/CurrentTradeRoundPage';
import { TradeRoundsPage } from './pages/TradeRoundsPage';
import { TradeRoundPage } from './pages/TradeRoundPage';
import React from 'react';
import { Layout } from './components/layout/Layout';
import { OverviewPage } from './pages/OverviewPage';
import { IncomeTaxPage } from './pages/IncomeTaxPage';
import { ChecksPage } from './pages/ChecksPage';
import { LoginPage } from './pages/auth/LoginPage';
import { LoadingPage } from './pages/LoadingPage';
import { CurrentProvider } from './hooks/useCurrent';
import { ForgotPassPage } from './pages/auth/ForgotPassPage';
import { RegisterPage } from './pages/auth/RegisterPage';
import { ResetPassPage } from './pages/auth/ResetPassPage';
import { AcceptInvitePage } from './pages/auth/AcceptInvitePage';
import { SettingsPage } from './pages/settings/SettingsPage';

export const Router = () => <RouterProvider router={router} />;

export const Paths = {
  login: '/login',
  forgotPassword: '/forgot-password',
  register: '/register',
};

const router = createBrowserRouter([
  {
    path: Paths.login,
    element: <LoginPage />,
  },
  {
    path: Paths.forgotPassword,
    element: <ForgotPassPage />,
  },
  {
    path: Paths.register,
    element: <RegisterPage />,
  },
  {
    path: 'reset-password',
    element: <ResetPassPage />,
  },
  {
    path: 'accept-invite',
    element: <AcceptInvitePage />,
  },
  {
    path: '/',
    element: (
      <CurrentProvider loader={<LoadingPage />}>
        <Layout />
      </CurrentProvider>
    ),
    errorElement: <Navigate to="/" replace />,
    children: [
      {
        path: '',
        element: <OverviewPage />,
      },
      {
        path: 'transactions',
        element: <TransactionsPage />,
      },
      {
        path: 'participants',
        element: <ParticipantsPage />,
      },
      {
        path: 'participants/:participantId',
        element: <ParticipantPage />,
      },
      {
        path: 'certificates',
        element: <CertificatesPage />,
      },
      {
        path: 'shares',
        element: <SharesPage />,
      },
      {
        path: 'shares/:shareId',
        element: <SharePage />,
      },
      {
        path: 'valuations',
        element: <ValuationsPage />,
      },
      {
        path: 'valuations/:valuationId',
        element: <ValuationPage />,
      },
      {
        path: 'dividends',
        element: <DividendsPage />,
      },
      {
        path: 'dividends/:dividendId',
        element: <DividendPage />,
      },
      {
        path: 'trade-round',
        element: <CurrentTradeRoundPage />,
      },
      {
        path: 'trade-rounds',
        element: <TradeRoundsPage />,
      },
      {
        path: 'trade-rounds/:tradeRoundId',
        element: <TradeRoundPage />,
      },
      {
        path: 'income-tax',
        element: <IncomeTaxPage />,
      },
      {
        path: 'checks',
        element: <ChecksPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ],
  },
]);
