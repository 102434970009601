export class ApiError extends Error {
  public constructor(
    public readonly status: number,
    public readonly message: string,
    public readonly errors?: ApiPropertyError[]
  ) {
    super();
  }
}

export interface ApiErrorResponse {
  message: string;
  errors?: ApiPropertyError[];
}

export interface ApiPropertyError {
  property: string;
  constraints: Record<string, string>;
}

export function asApiErrorResponse(err: unknown): ApiErrorResponse {
  if (isErrorObject(err)) {
    return err;
  }

  return {
    message: 'Unexpected error',
  };
}

function isErrorObject(err: unknown): err is ApiErrorResponse {
  return err != null && typeof err === 'object' && 'message' in err;
}

export function asApiError(err: unknown): ApiError {
  if (err instanceof ApiError) {
    return err;
  }

  let message = 'Er is een onverwachte fout opgetreden';
  if (isErrorObject(err)) {
    message = err.message;
  }

  return new ApiError(500, message);
}
