import { Certificate } from '../../model/Certificate';
import { PaginatedTable } from '../table/PaginatedTable';
import { PagedData } from '../../hooks/usePagedData';
import { ParticipantLink } from '../ParticipantLink';

export interface Props {
  certificates: PagedData<Certificate>;
  participantId?: number;
}

export const CertificatesTable = ({ certificates, participantId }: Props) => (
  <PaginatedTable
    pagedData={certificates}
    columns={[
      {
        name: 'Naam',
        valueFn: (c) => c.fullName,
      },
      !participantId && {
        name: 'Eigenaar',
        valueFn: (c) => c.owner && <ParticipantLink participant={c.owner} />,
      },
    ]}
  />
);
