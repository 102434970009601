import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { ParticipantIncomeTax } from '../model/ParticipantIncomeTax';

export class IncomeTaxService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public getParticipantIncomeTax(): Promise<ParticipantIncomeTax> {
    return this.api.jsonGet('/api/income-tax');
  }
}
