import { useCallback, useState } from 'react';
import { Input } from '../../components/form/Input';
import styles from './AuthPage.module.scss';
import { useApi } from '../../hooks/useApi';
import { asApiError } from '../../services/ApiError';
import { Link, useNavigate } from 'react-router-dom';
import { Paths } from '../../router';
import { useBooleanQuery } from '../../hooks/query/useBooleanQuery';
import { Form, FormField } from '../../components/form/Form';
import { useQuery } from '../../hooks/query/useQuery';

export const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [wasPasswordReset] = useBooleanQuery('password-reset');
  const api = useApi();
  const navigate = useNavigate();
  const [redirect] = useQuery('redirect', '/');

  const submit = useCallback(async () => {
    try {
      await api.auth.login({ email, password });
      navigate(redirect);
    } catch (e) {
      if (asApiError(e).status === 401) {
        throw new Error('Ongeldig e-mailadres of wachtwoord');
      }
      throw e;
    }
  }, [api.auth, email, navigate, password, redirect]);

  return (
    <div className={styles.authPage}>
      <div className={styles.modal}>
        <h1>Inloggen bij ParticiPro</h1>
        {wasPasswordReset && (
          <p>Je nieuwe wachtwoord is ingesteld, je kan hier nu mee inloggen.</p>
        )}
        <Form
          onSubmit={submit}
          submitLabel="Inloggen"
          altActionLabel="Wachtwoord vergeten?"
          onAltAction={() => navigate(Paths.forgotPassword)}
          fullWidth
        >
          <FormField label="E-mailadres">
            <Input type="email" value={email} onChange={setEmail} required />
          </FormField>
          <FormField label="Wachtwoord">
            <Input
              type="password"
              value={password}
              onChange={setPassword}
              required
            />
          </FormField>
        </Form>
        <div className={styles.bottomText}>
          Heb je nog geen account? <Link to={Paths.register}>Registreren</Link>
        </div>
      </div>
    </div>
  );
};
