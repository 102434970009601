import { HtmlInputProps } from './Input';

export type TextInputType = 'text' | 'email' | 'date' | 'password';

export interface Props extends Omit<HtmlInputProps, 'onChange' | 'type'> {
  value: string | undefined;
  onChange: (value: string) => void;
  type?: TextInputType;
}

export const TextInput = ({ value, onChange, ...props }: Props) => (
  <input
    {...props}
    value={value ?? ''}
    onChange={(e) => onChange(e.currentTarget.value)}
  />
);

export function isTextInputType(type: string): type is TextInputType {
  return (
    type === 'text' ||
    type === 'email' ||
    type === 'date' ||
    type === 'password'
  );
}
