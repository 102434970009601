import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import {
  CreateTransactions,
  Transaction,
  TransactionsFilter,
} from '../model/Transaction';
import { Certificate } from '../model/Certificate';

export class TransactionsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
    this.listTransactions = this.listTransactions.bind(this);
  }

  public listTransactions(
    page: PageParameters,
    filter?: TransactionsFilter
  ): Promise<Page<Transaction>> {
    return this.api.jsonGet(`/api/transactions`, { ...page, ...filter });
  }

  public createTransactions(
    transactions: CreateTransactions
  ): Promise<Certificate[]> {
    return this.api.jsonPost('/api/transactions', transactions);
  }
}
