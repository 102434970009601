import { ReactNode, useState } from 'react';
import { ButtonGroup } from '../ButtonGroup';
import { Button } from '../Button';
import { Form } from '../Form';

type ChildFn = (props: { editing: boolean }) => ReactNode;

export interface Props {
  children: ReactNode | ChildFn;
  onSubmit: () => void | Promise<void>;
  onCancel?: () => void;
  onEdit?: () => void;
  canEdit?: boolean;
}

export const EditableForm = ({
  children,
  onSubmit,
  onCancel,
  onEdit,
  canEdit,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  return (
    <Form
      onSubmit={async () => {
        setSaving(true);
        try {
          await onSubmit();
          setEditing(false);
        } finally {
          setSaving(false);
        }
      }}
    >
      {typeof children === 'function' ? children({ editing }) : children}
      {canEdit !== false && (
        <div className="buttons">
          {editing ? (
            <ButtonGroup>
              <Button
                type="submit"
                text="Opslaan"
                icon="check"
                loading={saving}
              />
              <Button
                text="Annuleren"
                icon="xmark"
                onClick={() => {
                  setEditing(false);
                  onCancel && onCancel();
                }}
                disabled={saving}
              />
            </ButtonGroup>
          ) : (
            <Button
              text="Bewerken"
              icon="pencil"
              onClick={() => {
                setEditing(true);
                onEdit && onEdit();
              }}
            />
          )}
        </div>
      )}
    </Form>
  );
};
