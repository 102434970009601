import { ApiClient } from './ApiClient';
import { User } from '../model/User';
import { Tenant } from '../model/Tenant';

export class AuthService {
  private readonly api: ApiClient;

  public constructor() {
    // Here we want to create a new instance instead of using an injected one,
    // since for all these calls we want to throw errors instead of redirecting to login when getting a 401.
    this.api = new ApiClient({
      throwOnUnauthorized: true,
    });
  }

  public tryGetCurrent(): Promise<CurrentAuth> {
    return this.api.jsonGet('/api/auth/current');
  }

  public login(data: Login): Promise<void> {
    return this.api.jsonPost('/auth/login', data);
  }

  public forgotPassword(data: ForgotPass): Promise<void> {
    return this.api.jsonPost('/auth/forgot-password', data);
  }

  public resetPassword(data: ResetPass): Promise<void> {
    return this.api.jsonPost('/auth/reset-password', data);
  }

  public register(data: Register): Promise<void> {
    return this.api.jsonPost('/auth/register', data);
  }
}

export interface CurrentAuth {
  user: User;
  tenant: Tenant;
}

interface Login {
  email: string;
  password: string;
}

interface ForgotPass {
  email: string;
}

interface ResetPass {
  email: string;
  password: string;
  token: string;
}

export interface Register {
  userName: string;
  email: string;
  password: string;
  companyName: string;
}
