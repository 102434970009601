import { useApi } from '../../hooks/useApi';
import { useAssertCurrent } from '../../hooks/useCurrent';
import { useFormValue } from '../../hooks/useFormValue';
import { UpdateUser } from '../../model/User';
import { useCallback } from 'react';
import { Form, FormField } from '../../components/form/Form';
import { Input } from '../../components/form/Input';

export const UserSettings = () => {
  const api = useApi();
  const { user: initialUser } = useAssertCurrent();
  const [user, setField] = useFormValue<UpdateUser>({
    name: initialUser.name,
    email: initialUser.email,
  });

  const onSubmit = useCallback(async () => {
    await api.users.updateUser(user);
  }, [api.users, user]);

  return (
    <div>
      <Form onSubmit={onSubmit} submitLabel="Opslaan">
        <FormField label="Naam">
          <Input value={user.name} onChange={setField('name')} required />
        </FormField>
        <FormField label="E-mailadres">
          <Input
            value={user.email}
            onChange={setField('email')}
            required
            placeholder="gebruiker@example.com"
          />
        </FormField>
      </Form>
    </div>
  );
};
