import { useCallback, useEffect, useState } from 'react';
import { useApi } from './useApi';
import { BlobEntityType, BlobList } from '../model/Blob';

export function useBlobs(
  type: BlobEntityType,
  id: number
): [BlobList | undefined, (f: File) => void] {
  const [blobs, setBlobs] = useState<BlobList | undefined>();
  const api = useApi();

  const reload = useCallback(() => {
    api.blob.list(type, id).then(setBlobs);
  }, [api.blob, id, type]);

  useEffect(() => {
    reload();
  }, [reload]);

  const upload = useCallback(
    (f: File) => api.blob.upload(type, id, f).then(reload),
    [api.blob, id, reload, type]
  );

  return [blobs, upload];
}
