import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import {
  CurrentParticipant,
  InviteParticipant,
  Participant,
  ParticipantsFilter,
  ParticipantSummary,
  UpdateParticipant,
} from '../model/Participant';

export class ParticipantsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
    this.listParticipants = this.listParticipants.bind(this);
  }

  public getCurrentParticipant(): Promise<CurrentParticipant> {
    return this.api.jsonGet('/api/participant');
  }

  public listParticipants(
    page: PageParameters,
    filter?: ParticipantsFilter
  ): Promise<Page<ParticipantSummary>> {
    return this.api.jsonGet(`/api/participants`, { ...page, ...filter });
  }

  public inviteParticipant(
    participant: InviteParticipant
  ): Promise<Participant> {
    return this.api.jsonPost('/api/participants', participant);
  }

  public getParticipant(id: number): Promise<ParticipantSummary> {
    return this.api.jsonGet(`/api/participants/${id}`);
  }

  public updateParticipant(
    id: number,
    updates: UpdateParticipant
  ): Promise<void> {
    return this.api.jsonPut(`/api/participants/${id}`, updates);
  }

  public archiveParticipant(id: number): Promise<void> {
    return this.api.jsonPost(`/api/participants/${id}/archive`, {});
  }

  public unarchiveParticipant(id: number): Promise<void> {
    return this.api.jsonPost(`/api/participants/${id}/unarchive`, {});
  }
}
