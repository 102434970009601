import { FileHandleFunc, useFileInput } from './hooks/useFileInput';
import { Button } from './components/form/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface Props {
  text?: string;
  icon?: IconProp;
  onSelectFile: FileHandleFunc;
}

export const SelectFileButton = ({ text, icon, onSelectFile }: Props) => {
  const [selectFile, selectFileLoading] = useFileInput();

  return (
    <Button
      text={text ?? 'Document uploaden'}
      icon={icon ?? 'upload'}
      onClick={() => selectFile(onSelectFile)}
      loading={selectFileLoading}
    />
  );
};
