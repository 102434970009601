import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export function useQuery(
  key: string,
  defaultValue = ''
): [string, (newValue: string) => void] {
  const location = useLocation();
  const navigate = useNavigate();

  const search = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const setValue = useCallback(
    (newValue: string) => {
      search.set(key, newValue);

      // Call navigate with the current hash, to preserve that while changing the search.
      navigate({
        search: search.toString(),
        hash: location.hash,
      });
    },
    [key, location.hash, navigate, search]
  );

  return [search.get(key) ?? defaultValue, setValue];
}
