import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { Page } from '../model/Page';
import {
  CreateValuation,
  UpdateValuation,
  Valuation,
} from '../model/Valuation';

export class ValuationsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
    this.listValuations = this.listValuations.bind(this);
  }

  public listValuations(): Promise<Page<Valuation>> {
    return this.api.jsonGet('/api/valuations');
  }

  public createValuation(valuation: CreateValuation): Promise<Valuation> {
    return this.api.jsonPost('/api/valuations', valuation);
  }

  public getValuation(id: number): Promise<Valuation> {
    return this.api.jsonGet(`/api/valuations/${id}`);
  }

  public updateValuation(
    id: number,
    updates: UpdateValuation
  ): Promise<Valuation> {
    return this.api.jsonPut(`/api/valuations/${id}`, updates);
  }
}
