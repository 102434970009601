import { Participant } from '../model/Participant';
import { Link } from 'react-router-dom';

export interface Props {
  participant: Participant;
}

export const ParticipantLink = ({ participant }: Props) => (
  <Link to={`/participants/${participant.id}`}>{participant.user.name}</Link>
);
