import { useDocumentEvent } from './useDocumentEvent';
import { RefObject } from 'react';

export function useClickOutside(
  ref: RefObject<HTMLElement>,
  onClick: () => void
) {
  useDocumentEvent('mousedown', (e) => {
    if (
      ref.current &&
      e.target instanceof Node &&
      !ref.current.contains(e.target)
    ) {
      onClick();
    }
  });
}
