import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { useCallback } from 'react';
import { Form, FormField } from '../../components/form/Form';
import { Input } from '../../components/form/Input';
import { UpdateTenantShares } from '../../model/Tenant';
import { useAssertCurrent } from '../../hooks/useCurrent';

export const ShareSettings = () => {
  const api = useApi();
  const { tenant } = useAssertCurrent();
  const [updates, setField] = useFormValue<UpdateTenantShares>({
    totalShares: tenant.totalShares ?? 0,
    certificatesPerShare: tenant.certificatesPerShare ?? 0,
  });

  const onSubmit = useCallback(async () => {
    await api.tenants.updateShares(updates);
  }, [api.tenants, updates]);

  return (
    <div>
      <Form onSubmit={onSubmit} submitLabel="Opslaan">
        <FormField label="Totaal aantal aandelen">
          <Input
            type="number"
            min={0}
            value={updates.totalShares}
            onChange={setField('totalShares')}
            required
            placeholder="120"
          />
        </FormField>
        <FormField label="Certificaten per aandeel">
          <Input
            type="number"
            min={0}
            value={updates.certificatesPerShare}
            onChange={setField('certificatesPerShare')}
            required
            placeholder="1000"
          />
        </FormField>
      </Form>
    </div>
  );
};
