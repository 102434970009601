import { Participant } from './Participant';
import { Option } from '../components/form/Select';
import { Certificate } from './Certificate';

export interface Transaction {
  id: number;
  date: string;
  participant: Participant;
  type: TransactionType;
  certificates: Certificate[];
  price?: number;
}

export type TransactionType = 'buy' | 'sell' | 'gift';

export interface TransactionsFilter {
  participantId?: number;
  type?: TransactionType;
}

export interface CreateTransactions {
  participantId: number;
  type: TransactionType;
  amount: number;
  date: string;
}

export const transactionTypeNames: Record<TransactionType, string> = {
  buy: 'Koop',
  sell: 'Verkoop',
  gift: 'Gift',
};

export const transactionTypeOptions: Option<TransactionType>[] = [
  {
    value: 'buy',
    displayValue: transactionTypeNames.buy,
  },
  {
    value: 'sell',
    displayValue: transactionTypeNames.sell,
  },
  {
    value: 'gift',
    displayValue: transactionTypeNames.gift,
  },
];
