import { PagedData } from '../../hooks/usePagedData';
import { formatDate } from '../../utils/date';
import { PaginatedTable } from '../table/PaginatedTable';
import { Transaction, transactionTypeNames } from '../../model/Transaction';
import { formatEuro } from '../../utils/currency';
import { ParticipantLink } from '../ParticipantLink';
import { useState } from 'react';
import { TransactionDrawer } from '../drawers/TransactionDrawer';

export interface Props {
  transactions: PagedData<Transaction>;
  participantId?: number;
}

export const TransactionsTable = ({ transactions, participantId }: Props) => {
  const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

  return (
    <>
      <PaginatedTable
        pagedData={transactions}
        onClickRow={(r) => setSelectedTransaction(r)}
        columns={[
          {
            name: 'Datum',
            valueFn: (t) => formatDate(t.date),
          },
          !participantId && {
            name: 'Deelnemer',
            valueFn: (t) => <ParticipantLink participant={t.participant} />,
          },
          {
            name: 'Type',
            valueFn: (t) => transactionTypeNames[t.type],
          },
          {
            name: 'Certificaten',
            valueFn: (t) => t.certificates.length,
          },
          {
            name: 'Prijs',
            valueFn: (t) => t.price != null && formatEuro(t.price),
          },
        ]}
      />
      {selectedTransaction && (
        <TransactionDrawer
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(undefined)}
        />
      )}
    </>
  );
};
