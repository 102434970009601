import { StatCard, StatCards } from '../components/StatCard';
import React, { useEffect, useState } from 'react';
import { Stats } from '../model/Stats';
import { useApi } from '../hooks/useApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPercent } from '../utils/percent';
import { formatEuro } from '../utils/currency';
import { ValueChart } from '../components/charts/ValueChart';
import { CertificatesChart } from '../components/charts/CertificatesChart';
import { TradeVolumeChart } from '../components/charts/TradeVolumeChart';
import { ParticipantsChart } from '../components/charts/ParticipantsChart';
import { PageHeader } from '../components/layout/PageHeader';
import styles from './OverviewPage.module.scss';

export const OverviewPage = () => {
  const [stats, setStats] = useState<Stats>();
  const api = useApi();

  useEffect(() => {
    api.stats.getStats().then(setStats);
  }, [api]);

  return (
    <main className={styles.overviewPage}>
      <PageHeader title="Welkom bij ParticiPro" />
      {stats ? (
        <StatCards colored>
          <StatCard value={stats.participants} label="Certificaathouders" />
          <StatCard
            value={stats.assignedCerts}
            label="Toegewezen certificaten"
          />
          <StatCard
            value={stats.totalCerts - stats.assignedCerts}
            label="Vrije certificaten"
          />
          <StatCard
            value={formatPercent(stats.employeeOwnedRatio)}
            label="Totale belang"
          />
          <StatCard
            value={formatEuro(stats.certValue)}
            label="Certificaatwaarde"
          />
          <ValueChart valueOverTime={stats.valueOverTime} />
          <CertificatesChart certsOverTime={stats.certsOverTime} />
          <TradeVolumeChart tradeVolumeOverTime={stats.tradeVolumeOverTime} />
          <ParticipantsChart
            participantsOverTime={stats.participantsOverTime}
          />
        </StatCards>
      ) : (
        <FontAwesomeIcon icon="spinner" pulse className="spinner" size="2x" />
      )}
    </main>
  );
};
