import { PaginatedTable } from '../components/table/PaginatedTable';
import { useApi } from '../hooks/useApi';
import { Button } from '../components/form/Button';
import { useState } from 'react';
import { usePagedData } from '../hooks/usePagedData';
import { CreateShareModal } from '../components/modals/CreateShareModal';
import { formatDate } from '../utils/date';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../components/layout/PageHeader';

export const SharesPage = () => {
  const api = useApi();
  const sharesData = usePagedData(api.shares.listShares);
  const [createShareModal, setCreateShareModal] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <main className="shares-page">
        <PageHeader
          title="Aandelen"
          actions={
            <Button
              text="Nieuw aandeel"
              icon="plus"
              onClick={() => setCreateShareModal(true)}
              kind="primary"
            />
          }
        />
        <PaginatedTable
          pagedData={sharesData}
          onClickRow={(s) => navigate(`/shares/${s.id}`)}
          columns={[
            {
              name: 'Naam',
              valueFn: (s) => s.name,
            },
            {
              name: 'Verkregen',
              valueFn: (s) => formatDate(s.acquireDate),
            },
          ]}
        />
      </main>
      {createShareModal && (
        <CreateShareModal
          onClose={() => setCreateShareModal(false)}
          onSubmit={(s) => navigate(`/shares/${s.id}`)}
        />
      )}
    </>
  );
};
