import React, { ReactNode } from 'react';

export interface Props {
  children: ReactNode;
}

export const StatusLayout = ({ children }: Props) => (
  <div className="status-layout">
    <div className="title">ParticiPro</div>
    <em className="subtitle">Certificate Administration and Tracking System</em>
    <div className="status">{children}</div>
  </div>
);
