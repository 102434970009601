import { useEffect, useState } from 'react';
import { Checks } from '../model/Checks';
import { PageLoading } from '../components/layout/PageLoading';
import { useApi } from '../hooks/useApi';
import { Link } from 'react-router-dom';
import { PageHeader } from '../components/layout/PageHeader';

export const ChecksPage = () => {
  const api = useApi();
  const [checks, setChecks] = useState<Checks>();

  useEffect(() => {
    api.checks.getChecks().then(setChecks);
  }, [api.checks]);

  if (!checks) {
    return <PageLoading />;
  }

  return (
    <main>
      <PageHeader title="Checks" />
      {checks.count === 0 ? (
        <p>Alles is in orde!</p>
      ) : (
        <p>Er zijn {checks.count} problemen gevonden:</p>
      )}
      <div className="table">
        <table>
          <tbody>
            {checks.participants.map((m, i) => (
              <CheckLink
                key={i}
                message={m.message}
                link={`/participants/${m.id}`}
              />
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

interface CheckProps {
  message: string;
  link: string;
}

const CheckLink = ({ message, link }: CheckProps) => (
  <tr>
    <td>
      <Link to={link}>{message}</Link>
    </td>
  </tr>
);
