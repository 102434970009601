import { Props as TextInputProps, TextInputType } from '../TextInput';
import { NumberInputType, Props as NumberInputProps } from '../NumberInput';
import { formatDate } from '../../../utils/date';
import { formatEuro, formatWholeEuros } from '../../../utils/currency';
import { Email } from '../../Email';
import { formatPercentWhole } from '../../../utils/percent';

export type Props =
  | {
      type?: TextInputType;
      value: TextInputProps['value'];
    }
  | {
      type: NumberInputType;
      value: NumberInputProps['value'];
    };

export const DisplayValue = ({ type, value }: Props) => {
  let result: string | number | undefined | JSX.Element = value;

  switch (type) {
    case 'date':
      result = value != null ? formatDate(value) : '-';
      break;
    case 'euro':
      result = formatEuro(value);
      break;
    case 'euro-whole':
      result = formatWholeEuros(value);
      break;
    case 'number':
      result = value.toString(10);
      break;
    case 'percent':
      result = formatPercentWhole(value);
      break;
    case 'email':
      if (value) {
        result = <Email value={value} />;
      }
      break;
  }

  return <span>{result}</span>;
};
