import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { UpdatePassword } from '../../model/User';
import { useCallback, useState } from 'react';
import { Form, FormField } from '../../components/form/Form';
import { Input } from '../../components/form/Input';

export const PasswordSettings = () => {
  const api = useApi();
  const [updates, setField, setUpdates] = useFormValue<UpdatePassword>({
    currentPassword: '',
    newPassword: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = useCallback(async () => {
    if (updates.newPassword !== confirmPassword) {
      throw new Error('Je nieuwe wachtwoorden komen niet overeen.');
    }

    await api.users.updatePassword(updates);

    // Clear the inputs.
    setUpdates({
      currentPassword: '',
      newPassword: '',
    });
    setConfirmPassword('');
  }, [api.users, confirmPassword, setUpdates, updates]);

  return (
    <div>
      <Form onSubmit={onSubmit} submitLabel="Opslaan">
        <FormField label="Huidige wachtwoord">
          <Input
            type="password"
            value={updates.currentPassword}
            onChange={setField('currentPassword')}
            required
          />
        </FormField>
        <FormField label="Nieuw wachtwoord">
          <Input
            type="password"
            value={updates.newPassword}
            onChange={setField('newPassword')}
            required
          />
        </FormField>
        <FormField label="Herhaal wachtwoord">
          <Input
            type="password"
            value={confirmPassword}
            onChange={setConfirmPassword}
            required
          />
        </FormField>
      </Form>
    </div>
  );
};
