import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { today } from '../../utils/date';
import { Input } from '../form/Input';
import { CreateTradeRound, TradeRoundDetails } from '../../model/TradeRound';
import { FormField, Form } from '../form/Form';

export const CreateTradeRoundModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<TradeRoundDetails>) => {
  const api = useApi();
  const [tradeRound, setField] = useFormValue<CreateTradeRound>(() => ({
    startDate: today(),
    endDate: today(),
  }));

  return (
    <Modal title="Nieuwe handelsronde" onClose={onClose}>
      <Form
        submitLabel="Opslaan"
        onSubmit={() =>
          api.trading.createTradeRound(tradeRound).then((r) => {
            onSubmit(r);
            onClose();
          })
        }
        fullWidth
      >
        <FormField label="Startdatum">
          <Input
            type="date"
            value={tradeRound.startDate}
            onChange={setField('startDate')}
            required
          />
        </FormField>
        <FormField label="Einddatum">
          <Input
            type="date"
            value={tradeRound.endDate}
            onChange={setField('endDate')}
            required
          />
        </FormField>
      </Form>
    </Modal>
  );
};
