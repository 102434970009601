import { useCallback, useState } from 'react';

export type FileHandleFunc = (f: File) => void | Promise<void>;

export function useFileInput(): [(fn: FileHandleFunc) => void, boolean] {
  const [loading, setLoading] = useState(false);

  const selectFile = useCallback((fn: (f: File) => void | Promise<void>) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.hidden = true;
    input.accept = 'application/pdf';

    input.addEventListener('change', async (e) => {
      const target = e.currentTarget ?? e.target;
      if (target instanceof HTMLInputElement) {
        const file = target.files?.[0];
        if (file) {
          setLoading(true);
          await Promise.resolve(fn(file)).finally(() => setLoading(false));
        }
      }
      input.remove();
    });

    input.click();
  }, []);

  return [selectFile, loading];
}
