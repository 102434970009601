import { useState } from 'react';
import { useFormValue } from '../hooks/useFormValue';
import {
  CreateTradeRoundRegistration,
  tradeRoundRegistrationTypeOptions,
} from '../model/TradeRound';
import { Input } from './form/Input';
import { Select } from './form/Select';
import { formatEuro } from '../utils/currency';
import { transactionTypeNames } from '../model/Transaction';
import { FormField, Form } from './form/Form';
import { Checkbox } from './form/Checkbox';

export interface Props {
  certPrice: number;
  onSubmit: (registration: CreateTradeRoundRegistration) => void;
}

export const TradeRoundRegistrationForm = ({ certPrice, onSubmit }: Props) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [reg, setField] = useFormValue<CreateTradeRoundRegistration>({
    type: 'buy',
    requestedAmount: 1,
  });

  return (
    <Form
      onSubmit={() => onSubmit(reg)}
      submitLabel="Inschrijven"
      submitDisabled={!termsAgreed}
    >
      <FormField label="Type">
        <Select
          value={reg.type}
          onChange={setField('type')}
          options={tradeRoundRegistrationTypeOptions}
          required
        />
      </FormField>
      <FormField label="Aantal">
        <Input
          value={reg.requestedAmount}
          onChange={setField('requestedAmount')}
          required
          type="number"
          min={1}
        />
      </FormField>
      <FormField label="Bedrag">
        <div>
          {reg.requestedAmount} x {formatEuro(certPrice)} ={' '}
          {formatEuro(reg.requestedAmount * certPrice)}
        </div>
      </FormField>
      <FormField label="Voorwaarden">
        <Checkbox checked={termsAgreed} onChange={setTermsAgreed}>
          Ik ga akkoord met de voorwaarden verbonden aan de{' '}
          {transactionTypeNames[reg.type].toLowerCase()}, zoals genoemd in het
          participatiereglement.
        </Checkbox>
      </FormField>
    </Form>
  );
};
