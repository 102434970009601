import { Switch } from '@headlessui/react';
import { classNames } from '../../utils/classNames';
import styles from './Toggle.module.scss';

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}

export const Toggle = ({ checked, onChange, label }: Props) => {
  return (
    <div className={styles.toggle}>
      <Switch
        checked={checked}
        onChange={onChange}
        className={classNames(styles.switch, checked && styles.checked)}
      >
        <span className={styles.circle} />
      </Switch>
      <label>{label}</label>
    </div>
  );
};
