import { Pagination } from './Pagination';
import { PagedData } from '../../hooks/usePagedData';
import { Columns, Table } from './Table';
import { ReactNode } from 'react';

export interface Props<D> {
  pagedData: PagedData<D>;
  columns: Columns<D>;
  onClickRow?: (row: D) => void;
  title?: string;
  actions?: ((row: D) => ReactNode) | false;
}

export function PaginatedTable<D>({ pagedData, ...props }: Props<D>) {
  return (
    <div>
      <Table
        {...props}
        data={pagedData.page?.data}
        loading={pagedData.loading}
      />
      {pagedData.page && (
        <Pagination page={pagedData.page} onNavigate={pagedData.setPageNum} />
      )}
    </div>
  );
}
