import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { Page, PageParameters } from '../model/Page';
import { Certificate, CertificatesFilter } from '../model/Certificate';

export class CertificatesService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
    this.listCertificates = this.listCertificates.bind(this);
  }

  public listCertificates(
    page: PageParameters,
    filter?: CertificatesFilter
  ): Promise<Page<Certificate>> {
    return this.api.jsonGet('/api/certificates', { ...page, ...filter });
  }
}
