import { ReactNode } from 'react';
import styles from './PageHeader.module.scss';

export interface Props {
  title: ReactNode;
  actions?: ReactNode;
}

export const PageHeader = ({ title, actions }: Props) => (
  <div className={styles.pageHeader}>
    <h1>{title}</h1>
    {actions}
  </div>
);
