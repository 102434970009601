import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import {
  CreateTradeRound,
  CreateTradeRoundRegistration,
  TradeRoundDetails,
  TradeRoundStatus,
  UpdateTradeRoundRegistration,
} from '../model/TradeRound';
import { Page } from '../model/Page';

export class TradeRoundsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
    this.listTradeRounds = this.listTradeRounds.bind(this);
  }

  public listTradeRounds(): Promise<Page<TradeRoundDetails>> {
    return this.api.jsonGet('/api/trade-rounds');
  }

  public getTradeRound(id: number): Promise<TradeRoundDetails> {
    return this.api.jsonGet(`/api/trade-rounds/${id}`);
  }

  public createTradeRound(
    tradeRound: CreateTradeRound
  ): Promise<TradeRoundDetails> {
    return this.api.jsonPost('/api/trade-rounds', tradeRound);
  }

  public getTradeRoundStatus(): Promise<TradeRoundStatus> {
    return this.api.jsonGet('/api/trade-round');
  }

  public createTradeRoundRegistration(
    registration: CreateTradeRoundRegistration
  ): Promise<void> {
    return this.api.jsonPost('/api/trade-round', registration);
  }

  public createTransactionsForTradeRound(id: number): Promise<void> {
    return this.api.jsonPost(`/api/trade-rounds/${id}/transactions`, {});
  }

  public updateTradeRoundRegistration(
    id: number,
    registrationId: number,
    updates: UpdateTradeRoundRegistration
  ): Promise<void> {
    return this.api.jsonPut(
      `/api/trade-rounds/${id}/registrations/${registrationId}`,
      updates
    );
  }
}
