import { useCallback } from 'react';
import { useQuery } from './useQuery';

const FALSE_VALUE = 'false';

export function useBooleanQuery(
  key: string
): [boolean, (newValue: boolean) => void] {
  const [value, setValue] = useQuery(key, FALSE_VALUE);

  const setBooleanValue = useCallback(
    (newValue: boolean) => {
      setValue(String(newValue));
    },
    [setValue]
  );

  return [value.toLowerCase() !== FALSE_VALUE, setBooleanValue];
}
