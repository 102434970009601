import { CloseSubmitProps, Modal } from '../Modal';
import { useApi } from '../../hooks/useApi';
import { useFormValue } from '../../hooks/useFormValue';
import { CreateShare, Share } from '../../model/Share';
import { today } from '../../utils/date';
import { Input } from '../form/Input';
import { FormField, Form } from '../form/Form';

export const CreateShareModal = ({
  onClose,
  onSubmit,
}: CloseSubmitProps<Share>) => {
  const api = useApi();
  const [shares, setField] = useFormValue<CreateShare>(() => ({
    name: '',
    acquireDate: today(),
  }));

  return (
    <Modal title="Nieuw aandeel" onClose={onClose}>
      <Form
        submitLabel="Opslaan"
        onSubmit={() =>
          api.shares.createShare(shares).then((s) => {
            onSubmit(s);
            onClose();
          })
        }
        fullWidth
      >
        <FormField label="Naam">
          <Input value={shares.name} onChange={setField('name')} required />
        </FormField>
        <FormField label="Verkregen">
          <Input
            type="date"
            value={shares.acquireDate}
            onChange={setField('acquireDate')}
            required
          />
        </FormField>
      </Form>
    </Modal>
  );
};
