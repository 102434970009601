import { ReactNode } from 'react';
import styles from './Checkbox.module.scss';

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  children: ReactNode;
}

export const Checkbox = ({ onChange, children, ...props }: Props) => (
  <span className={styles.checkbox}>
    <input
      {...props}
      type="checkbox"
      onChange={(e) => onChange(e.currentTarget.checked)}
    />
    {children}
  </span>
);
