import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Props {
  showMenu: boolean;
  onToggleMenu: () => void;
}

export const TopBar = ({ showMenu, onToggleMenu }: Props) => (
  <div className="top-bar">
    <span className="app-name">ParticiPro</span>
    <FontAwesomeIcon
      icon={showMenu ? 'times' : 'bars'}
      onClick={onToggleMenu}
      className="menu-toggle"
    />
  </div>
);
