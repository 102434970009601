import { useCallback, useEffect, useState } from 'react';
import { Page, PageParameters } from '../model/Page';
import { useIntQuery } from './query/useIntQuery';

export type loadPageFn<D, F> = (
  page: PageParameters,
  filter?: F
) => Promise<Page<D>>;

export interface PagedData<D> {
  page?: Page<D>;
  setPageNum: (page: number) => void;
  reloadPage: () => void;
  loading: boolean;
}

export function usePagedData<D, F>(
  loadPage: loadPageFn<D, F>,
  filter?: F,
  pageName?: string
): PagedData<D> {
  const [page, setPage] = useState<Page<D>>();
  const [pageNum, setPageNum] = useIntQuery(
    pageName ? `page_${pageName}` : 'page',
    1
  );
  const [loading, setLoading] = useState(true);

  // Clamp the page number between 1 and the number of pages.
  useEffect(() => {
    // The first page is 1.
    if (pageNum < 1) {
      setPageNum(1);
      return;
    }

    // If we have pages, and if we are beyond the pages, set it to the last page.
    if (page && page.meta.pageCount > 0 && pageNum > page.meta.pageCount) {
      setPageNum(page.meta.pageCount);
    }
  }, [page, pageNum, setPageNum]);

  // We don't want to use the filter as a dependency directly,
  // since that will cause it to keep re-rendering.
  // Instead, we spread the keys and values out into an array,
  // which can then be spread out into the dependencies.
  // That way we only re-render when one of the keys or values changes.
  const filterDeps = Object.entries(filter || {}).flatMap((a) => a);

  const reloadPage = useCallback(() => {
    setLoading(true);
    loadPage({ page: pageNum }, filter).then((d) => {
      setPage(d);
      setLoading(false);
    });

    // See the above comment at filterDeps.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPage, pageNum, ...filterDeps]);

  useEffect(() => {
    reloadPage();
  }, [reloadPage]);

  return { page, setPageNum, reloadPage, loading };
}
