import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import { useAssertCurrent } from '../../hooks/useCurrent';
import { classNames } from '../../utils/classNames';
import { Role } from '../../model/Role';

export interface Props {
  visible: boolean;
  onClose: () => void;
  checksCount: number;
}

export const Menu = ({ visible, onClose, checksCount }: Props) => {
  const { participant } = useAssertCurrent();

  return (
    <nav className={classNames('menu', visible && 'visible')}>
      <Link to="/" className="app-name">
        ParticiPro
      </Link>
      <MenuItem
        icon="chart-simple"
        label="Overzicht"
        link="/"
        onClose={onClose}
      />
      <MenuItem
        icon="user"
        label="Mijn gegevens"
        link={`/participants/${participant?.id}`}
        onClose={onClose}
        participantOnly
      />
      <MenuItem
        adminOnly
        icon="users"
        label="Deelnemers"
        link="/participants"
        onClose={onClose}
      />
      <MenuItem
        participantOnly
        icon="handshake"
        label="Handelsronde"
        link="/trade-round"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="handshake"
        label="Handelsrondes"
        link="/trade-rounds"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="right-left"
        label="Transacties"
        link="/transactions"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="certificate"
        label="Certificaten"
        link="/certificates"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="file-contract"
        label="Aandelen"
        link="/shares"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="coins"
        label="Waarderingen"
        link="/valuations"
        onClose={onClose}
      />
      <MenuItem
        adminOnly
        icon="percent"
        label="Dividend"
        link="/dividends"
        onClose={onClose}
      />
      <MenuItem
        participantOrAdminOnly
        icon="file-invoice-dollar"
        label="Belastingaangifte"
        link="/income-tax"
        onClose={onClose}
      />
      <div className="spacer" />
      <MenuItem
        adminOnly
        icon="check"
        label="Checks"
        link="/checks"
        onClose={onClose}
        bubbleCounter={checksCount}
      />
      <MenuItem
        icon="gear"
        label="Instellingen"
        link="/settings"
        onClose={onClose}
      />
      <a className="menu-item" href="/auth/logout">
        <FontAwesomeIcon icon="right-from-bracket" />
        Uitloggen
      </a>
    </nav>
  );
};

interface MenuItemProps {
  icon: IconProp;
  label: string;
  link: string;
  adminOnly?: boolean;
  participantOnly?: boolean;
  participantOrAdminOnly?: boolean;
  onClose: () => void;
  bubbleCounter?: number;
}

const MenuItem = ({
  icon,
  label,
  link,
  adminOnly,
  participantOnly,
  participantOrAdminOnly,
  onClose,
  bubbleCounter,
}: MenuItemProps) => {
  const { roles, isParticipant } = useAssertCurrent();

  if (adminOnly && !roles.includes(Role.admin)) {
    return null;
  }

  if (participantOnly && !isParticipant) {
    return null;
  }

  if (participantOrAdminOnly && !isParticipant && !roles.includes(Role.admin)) {
    return null;
  }

  return (
    <NavLink to={link} className="menu-item" onClick={onClose}>
      <FontAwesomeIcon icon={icon} />
      {label}
      {!!bubbleCounter && <span className="bubble">{bubbleCounter}</span>}
    </NavLink>
  );
};
