import { ApiClient } from './ApiClient';
import { Injector } from 'react-service-injector';
import { UpdateTenant, UpdateTenantShares } from '../model/Tenant';

export class TenantsService {
  private readonly api: ApiClient;

  public constructor(injector: Injector) {
    this.api = injector.resolve(ApiClient);
  }

  public updateTenant(updates: UpdateTenant): Promise<void> {
    return this.api.jsonPut('/api/tenant', updates);
  }

  public updateShares(updates: UpdateTenantShares): Promise<void> {
    return this.api.jsonPut('/api/tenant/shares', updates);
  }
}
