import { Table } from '../table/Table';
import { LinkButton } from '../form/LinkButton';
import { BlobList } from '../../model/Blob';

export interface Props {
  blobs?: BlobList;
}

export const DocumentsTable = ({ blobs }: Props) => (
  <Table
    data={blobs?.blobs}
    loading={!blobs}
    columns={[
      {
        name: 'Naam',
        valueFn: (b) => b.name,
      },
    ]}
    actions={(b) => (
      <LinkButton
        kind="basic"
        href={`/api/blob/${blobs?.type}/${blobs?.id}/${encodeURIComponent(
          b.name
        )}`}
        download={b.name}
        icon="download"
        text="Download"
      />
    )}
  />
);
